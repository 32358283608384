import JSEncrypt from "jsencrypt";
import { displayErrorNotification } from "./errorHandling.ts";

interface EncryptionResult {
    success: boolean;
    encryptedText?: string;
    error?: string;
}

/**
 * Encrypts a message using RSA with the provided public key.
 * @param message The message to encrypt.
 * @param publicKey The RSA public key.
 * @returns An object containing the encryption status and result.
 */
const encryptRSA = (message: string, publicKey: string): EncryptionResult => {
    // @ts-expect-error: for some reason the library author decided to export the class through a barrel file and it breaks the type definition
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(publicKey);
    const encrypted = encryptor.encrypt(message);
    if (encrypted) {
        return { success: true, encryptedText: encrypted };
    }
    return { success: false, error: "Encryption failed" };
};

/**
 * Process and encrypt all relevant fields in the form.
 * @param elements The form elements.
 * @param publicKey The RSA public key.
 */
const processFormElements = (elements: NodeListOf<Element>, publicKey: string) => {
    if (!isValidPublicKey(publicKey)) displayErrorNotification(null, "Public key is not valid.", "Could not encrypt form data.");

    for (const element of elements) {
        const inputElement = element as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
        if (inputElement.value && !["_token", "_method", "g-recaptcha-response", "g-recaptcha-response-v3"].includes(inputElement.name)) {
            const result = encryptRSA(inputElement.value, publicKey);
            if (result.success && result.encryptedText) {
                inputElement.value = result.encryptedText;
                if (inputElement.type === "text") inputElement.setAttribute("type", "password");
            } else if (!result.success) {
                displayErrorNotification(null, "Form encryption failed. Please try again.", "Could not encrypt form data.");
            }
        }
    }
};

/**
 * Checks if the provided public key is valid.
 * @param key The public key to check.
 * @returns True if the key is valid, false otherwise.
 */
const isValidPublicKey = (key: string): boolean => {
    // TODO: Make this more robust
    return key.startsWith("-----BEGIN PUBLIC KEY-----");
};

/**
 * Handles the form submission event by encrypting the form data.
 */
const handleFormSubmission = (e: Event) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const publicKeyInput = document.getElementById("public_key") as HTMLInputElement;
    if (!form || !publicKeyInput) return;

    const elements = form.querySelectorAll("input, textarea, select");
    const passwordToggle = form.querySelector("#password_toggle");
    passwordToggle?.classList.add("hidden");

    processFormElements(elements, publicKeyInput.value);
};

document.addEventListener("submit", handleFormSubmission);
